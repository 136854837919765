import React, { lazy, Suspense, useEffect, useState } from 'react';

// context
import { useAuth } from 'context/authContext';

// screens
import { LoadingScreen } from 'pages/loadingScreen';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const Dashboard = lazy(() => import('./pages/index'));
const ParkingHome = lazy(() => import('./pages/loggedIn/reception/screens'));
const HomePage = lazy(() => import('./pages/public'));
const App: React.FC = () => {
    const [isAuth, setIsAuth] = useState(false);
    const [type, setType] = useState(0);

    // TODO: when api docs are ready adding in authentication setup
    const data = useAuth();

    useEffect(() => {
        setIsAuth(data.isAuthenticated);
        setType(data.type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            const currentDate = new Date();
            localStorage.setItem('tabOpenTime', currentDate.toString());
        };
    }, [data.isAuthenticated, data.type, data.language]);

    return (
        <Suspense fallback={<LoadingScreen />}>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {isAuth ? (
                type === 2 ? (
                    <ParkingHome /> // Reception
                ) : (
                    <Dashboard />
                )
            ) : (
                <HomePage isLoading={data.loadingState} />
            )}
        </Suspense>
    );
};

export default App;
