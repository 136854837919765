import axios from 'axios';
import Cookies from 'js-cookie';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import runtimeEnv from '@mars/heroku-js-runtime-env';
// import Cookies from 'js-cookie';

// const abortController = new AbortController();
let baseURL = process.env.REACT_APP_BASE_URL;
if (process.env.REACT_APP_BASE_URL === undefined) {
    baseURL = `${process.env.REACT_APP_BASE_URL}`;
}
// base api
export const apiBase = axios.create({
    baseURL,
    timeout: 40000, // 0 is unlimited.
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export const apiBasePublic = axios.create({
    baseURL,
    timeout: 40000, // 0 is unlimited.
    headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    },
});

export const ticketServiceApi = axios.create({
    baseURL: `https://pk-ticket-service-production.herokuapp.com/v2/api`,
    timeout: 4000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    },
});

export const authBase = axios.create({
    baseURL,
    timeout: 40000, // 0 is unlimited.
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

authBase.interceptors.response.use(
    (response) => {
        // Do something with response data
        return response;
    },
    (error) => {
        return Promise.resolve(error.response);
    }
);

authBase.interceptors.request.use(
    async (config) => {
        if (config.data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        } else {
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// TODO: revisit this
// we basically needs this to better work with
// how axios handles errors
apiBase.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            const searchParams = new URLSearchParams(window.location.search);
            const someParam = searchParams.get('userSessionExpired');
            if (someParam === 'true') {
                searchParams.delete('userSessionExpired');
                let newUrl = `${window.location.pathname}`;
                if (searchParams.toString().length > 0) {
                    newUrl = `${window.location.pathname}?${searchParams}`;
                }
                window.history.pushState({ path: newUrl }, '', newUrl);
            }
        }
        // Do something with response data
        return response;
    },
    (error) => {
        if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
        ) {
            // Cookies.remove('pk-base-admin');
            const searchParams = new URLSearchParams(window.location.search);
            const someParam = searchParams.get('userSessionExpired');
            if (!someParam) {
                searchParams.append('userSessionExpired', 'true');
                window.location.search = searchParams.toString();
            }
        }
        return Promise.resolve(error.response);
    }
);

apiBase.interceptors.request.use(
    (config) => {
        config.headers['Cache-Control'] = 'no-cache';
        config.headers.Pragma = 'no-cache';
        config.headers.Expires = '0';
        const cookies = Cookies.getJSON('pk-admin');
        if (cookies) {
            config.headers.Token = cookies.token;
        }

        if (config.data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        } else {
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiBasePublic.interceptors.response.use(
    (response) => {
        // Do something with response data
        return response;
    },
    (error) => {
        return Promise.resolve(error.response);
    }
);

apiBasePublic.interceptors.request.use((config) => {
    if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
    } else {
        config.headers['Content-Type'] = 'application/json';
    }
    return config;
});
